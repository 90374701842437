import {
  GET_TOURNAMENT_LOADERBOARD_INIT,
  GET_TOURNAMENT_LOADERBOARD_SUCCESS,
  GET_TOURNAMENT_LOADERBOARD_FAILED,
  GET_TOURNAMENT_LOADERBOARD_RESET,
} from "../../../constants";

const initialState = {
  loading: false,
  data: {},
  code: "",
  success: false,
  error: "",
};

const getTournamentLoaderboard = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_TOURNAMENT_LOADERBOARD_INIT:
      return {
        ...state,
        loading: true,
      };
    case GET_TOURNAMENT_LOADERBOARD_SUCCESS:
      let payload = action.payload;
      let newArr = [];
      action.payload?.data?.leaderboard?.forEach((item, index) => {
        newArr.push({
          ...item,
          id: index + Math.random() * 1000,
        });
      });
      payload.data.leaderboard = newArr;
      return {
        ...state,
        loading: false,
        code: action.payload.code,
        success: action.payload.success,
        data: payload.data,
        error: "",
      };
    case GET_TOURNAMENT_LOADERBOARD_FAILED:
      return {
        ...state,
        loading: false,
        code: action.payload.code || action.payload.status,
        success: action.payload.success || false,
        data: {},
        error: action.payload.message || action.payload.data || action.payload.error,
      };

    case GET_TOURNAMENT_LOADERBOARD_RESET:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default getTournamentLoaderboard;

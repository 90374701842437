export const SET_TAB = "SET_TAB";
export const SET_TAB_RESET = "SET_TAB_RESET";

const initialState = {
  tab: 4,
};

const setTab = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_TAB:
      return {
        ...state,
        tab: action.payload,
      };
    case SET_TAB_RESET:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default setTab;

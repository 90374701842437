import {
  ADD_NEW_PLAYER_INIT,
  ADD_NEW_PLAYER_SUCCESS,
  ADD_NEW_PLAYER_FAILED,
  ADD_NEW_PLAYER_RESET,
} from "../../../../constants";

const initialState = {
  loading: false,
  data: {},
  code: "",
  success: false,
  error: "",
};

const addNewPlayer = (state = initialState, action = {}) => {
  switch (action.type) {
    case ADD_NEW_PLAYER_INIT:
      return {
        ...state,
        loading: true,
      };
    case ADD_NEW_PLAYER_SUCCESS:
      return {
        ...state,
        loading: false,
        code: action.payload.code,
        success: action.payload.success,
        data: action.payload.data,
        error: "",
      };
    case ADD_NEW_PLAYER_FAILED:
      return {
        ...state,
        loading: false,
        code: action.payload.code || action.payload.status,
        success: action.payload.success || false,
        data: {},
        error: action.payload.message || action.payload.data || action.payload.error,
      };

    case ADD_NEW_PLAYER_RESET:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default addNewPlayer;

import {
  GET_TEAM_LISTS_INIT,
  GET_TEAM_LISTS_SUCCESS,
  GET_TEAM_LISTS_FAILED,
  GET_TEAM_LISTS_RESET,
} from "../../../../constants";

const initialState = {
  loading: false,
  data: {},
  code: "",
  success: false,
  error: "",
};

const getTeamLists = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_TEAM_LISTS_INIT:
      return {
        ...state,
        loading: true,
      };
    case GET_TEAM_LISTS_SUCCESS:
      return {
        ...state,
        loading: false,
        code: action.payload.code,
        success: action.payload.success,
        data: action.payload.data,
        error: "",
      };
    case GET_TEAM_LISTS_FAILED:
      return {
        ...state,
        loading: false,
        code: action.payload.code || action.payload.status,
        success: action.payload.success || false,
        data: {},
        error: action.payload.message || action.payload.data || action.payload.error,
      };

    case GET_TEAM_LISTS_RESET:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default getTeamLists;

import { SIDE_DRAWER_TOGGLE, SIDE_DRAWER_RESET } from "../../constants";

const initialState = {
  status: false,
  data: {},
};

const sideDrawer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SIDE_DRAWER_TOGGLE:
      return {
        ...state,
        status: action.status,
      };
    case SIDE_DRAWER_RESET:
      return {
        ...state,
        status: false,
        data: {},
      };
    default:
      return state;
  }
};

export default sideDrawer;

import { combineReducers } from "redux";

import toaster from "./Toaster/Toaster";
import sideDrawer from "./SideDrawer";
import modal from "./Modal";
import loading from "./Loading";

export default combineReducers({
  toaster,
  sideDrawer,
  modal,
  loading,
});

import {
  DELETE_GAMEPLAY_VIDEO_INIT,
  DELETE_GAMEPLAY_VIDEO_SUCCESS,
  DELETE_GAMEPLAY_VIDEO_FAILED,
  DELETE_GAMEPLAY_VIDEO_RESET,
} from "../../../constants";

const initialState = {
  loading: false,
  data: {},
  code: "",
  success: false,
  error: "",
};

const deleteGameplayVideo = (state = initialState, action = {}) => {
  switch (action.type) {
    case DELETE_GAMEPLAY_VIDEO_INIT:
      return {
        ...state,
        loading: true,
      };
    case DELETE_GAMEPLAY_VIDEO_SUCCESS:
      return {
        ...state,
        loading: false,
        code: action.payload.code,
        success: action.payload.success,
        data: action.payload.data,
        error: "",
      };
    case DELETE_GAMEPLAY_VIDEO_FAILED:
      return {
        ...state,
        loading: false,
        code: action.payload.code || action.payload.status,
        success: action.payload.success || false,
        data: {},
        error: action.payload.message || action.payload.data || action.payload.error,
      };

    case DELETE_GAMEPLAY_VIDEO_RESET:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default deleteGameplayVideo;

import {
  GET_TOURNAMENT_LIST_INIT,
  GET_TOURNAMENT_LIST_SUCCESS,
  GET_TOURNAMENT_LIST_FAILED,
  GET_TOURNAMENT_LIST_RESET,
} from "../../../constants";

const initialState = {
  loading: false,
  data: {},
  code: "",
  success: false,
  error: "",
};

const getTournamentList = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_TOURNAMENT_LIST_INIT:
      return {
        ...state,
        loading: true,
      };
    case GET_TOURNAMENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        code: action.payload.code,
        success: action.payload.success,
        data: action.payload.data,
        error: "",
      };
    case GET_TOURNAMENT_LIST_FAILED:
      return {
        ...state,
        loading: false,
        code: action.payload.code || action.payload.status,
        success: action.payload.success || false,
        data: {},
        error:
          action.payload.message || action.payload.data || action.payload.error,
      };

    case GET_TOURNAMENT_LIST_RESET:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default getTournamentList;

import { combineReducers } from "redux";

import common from "../Common/reducers";
import { LOGOUT_SUCCESS } from "../Components/constants";
import playverse from "../Components/reducers";

const appRedcucer = combineReducers({
  common,
  playverse,
});

const rootReducer = (state, action) => {
  return appRedcucer(state, action);
};

const initialReducer = (state, action) => {
  if (action.type === LOGOUT_SUCCESS) {
    state = undefined;
  }
  return rootReducer(state, action);
};

export default initialReducer;

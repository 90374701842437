export const TOASTER_MESSAGE_ALERT = "TOASTER_MESSAGE_ALERT";
export const REMOVE_TOASTER_MESSAGE_ALERT = "REMOVE_TOASTER_MESSAGE_ALERT";

export const SIDE_DRAWER_TOGGLE = "SIDE_DRAWER_TOGGLE";
export const SIDE_DRAWER_RESET = "SIDE_DRAWER_RESET";

export const MODAL_TOGGLE = "MODAL_TOGGLE";
export const MODAL_RESET = "MODAL_RESET";

export const VIEW_LOADING = "VIEW_LOADING";

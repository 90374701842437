import {
  GAME_BANNER_INIT,
  GAME_BANNER_SUCCESS,
  GAME_BANNER_FAILED,
  GAME_BANNER_RESET,
} from "../../../constants";

const initialState = {
  loading: false,
  data: [],
  code: "",
  success: false,
  error: "",
};

const gameBanner = (state = initialState, action = {}) => {
  switch (action.type) {
    case GAME_BANNER_INIT:
      return {
        ...state,
        loading: true,
      };
    case GAME_BANNER_SUCCESS:
      return {
        ...state,
        loading: false,
        code: action.payload.code,
        success: action.payload.success,
        data: action.payload.data,
        error: "",
      };
    case GAME_BANNER_FAILED:
      return {
        ...state,
        loading: false,
        code: action.payload.status,
        success: false,
        data: {},
        error: action.payload.message || action.payload.error,
      };
    case GAME_BANNER_RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default gameBanner;

import {
  GAMES_FILTER_INIT,
  GAMES_FILTER_SUCCESS,
  GAMES_FILTER_FAILED,
  GAMES_FILTER_RESET,
} from "../../../constants";

const initialState = {
  loading: false,
  data: {},
  code: "",
  success: false,
  error: "",
};

const gamesFilter = (state = initialState, action = {}) => {
  switch (action.type) {
    case GAMES_FILTER_INIT:
      return {
        ...state,
        loading: true,
      };
    case GAMES_FILTER_SUCCESS:
      return {
        ...state,
        loading: false,
        code: action.payload.code,
        success: action.payload.success,
        data: action.payload.data,
        error: "",
      };
    case GAMES_FILTER_FAILED:
      return {
        ...state,
        loading: false,
        code: action.payload.code,
        success: action.payload.success,
        data: {},
        error: action.payload.message || action.payload.data,
      };

    case GAMES_FILTER_RESET:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default gamesFilter;

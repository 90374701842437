import { combineReducers } from "redux";

import login from "./Login";
import logout from "./logout";
// Games
import gamesFilter from "./Games/ListingFilter";
import getGameList from "./Games/GetGameList";
import getGameId from "./Games/GetGameId";
import uploadIcon from "./Games/UploadIcon";
import editGameDetails from "./Games/EditGameDetails";
import gameDetailsById from "./Games/GetGameDetailsById";
import linkHtmlGame from "./Games/LinkHtmlGame";
import gameBanner from "./Games/GameBanner";
import gameVideo from "./Games/GameVideo";
import deleteBannerImage from "./Games/DeleteBannerImage";
import deleteGameplayVideo from "./Games/DeleteGameplayVideo";
import getFreeGameplays from "./Games/GetFreeGameplays";
import activateFreeGameplay from "./Games/ActivateFreeGameplay";
import deactivateFreeGameplay from "./Games/DeactivateFreeGameplay";
import getAllGameplayVideos from "./Games/GetAllGameplayVideos";
import getAllGameplayBanners from "./Games/GetAllGameplayBanners";
import changeBannerPriority from "./Games/ChangeBannerPriority";
import activateTournament from "./Games/ActivateTournament";
import getActivatedTournaments from "./Games/GetActivatedTournaments";
import deactivateTournament from "./Games/DeactivateTournament";
import activateGame from "./Games/ActivateGame";
// Tournaments
import createTournament from "./Tournaments/CreateTournament";
import getTournamentList from "./Tournaments/GetTournamentList";
import editTournamentDetails from "./Tournaments/EditTournamentDetails";
import editRewards from "./Tournaments/EditRewards";
import getTournamentDetails from "./Tournaments/GetTournamentDetails";
import deleteRewardRank from "./Tournaments/DeleteRewardRank";
import deployTournament from "./Tournaments/DeployTournament";
import getTournamentLoaderboard from "./Tournaments/GetTournamentLoaderboard";
import setTab from "./Tournaments/SetTab";


//MONETISATION

import getAdsList from "./Ads/GetAdsList";
import getBrandsList  from "./Ads/GetBrandsList";
import getCampaignsList  from "./Ads/GetCampaignsList";
import getBrandDetails from "./Ads/GetBrandDetails";
import createNewBrand from "./Ads/CreateNewBrand";
import getAdsDetails from "./Ads/GetAdsDetails";
import getCampaignsDetails from "./Ads/GetCampaignsDetails";
import createNewCampaign from "./Ads/CreateNewCampaign";
import getBrandCampaignsDetails from "./Ads/GetBrandCampaignsDetails";
import getAdsAssociatedDetails from "./Ads/GetAdsAssociatedDetails"
import createNewAds from "./Ads/CreateNewAds";
import getAllBrandsFiltered from "./Ads/GetAllBrandsFiltered";
import getAllCampaignsFiltered from "./Ads/GetAllCampaignsFiltered";
import uploadMonetisationIcon from "./Ads/UploadMonetisationIcon";
import uploadMonetisationVideo from "./Ads/UploadMonetisationVideo";
import activateBrands from "./Ads/ActivateBrands";
import activateCampaigns from "./Ads/ActivateCampaigns";
import activateAds from "./Ads/ActivateAds";
import editBrand from "./Ads/EditBrand";
import editCampaign from "./Ads/EditCampaign";
import editAd from "./Ads/EditAd";
import getAllAdSlotsFiltered from "./Ads/GetAllAdSlotsFiltered";
import getAllAdSlotsMapping from "./Ads/GetAllAdSlotsMapping";
import updateAdSlotsMapping from "./Ads/UpdateAdSlotsMapping";

// Game-Developer
import createNewGameDeveloper from "./GameDeveloper/CreateNewGameDeveloper";
import editGameDeveloper from "./GameDeveloper/EditGameDeveloper";
import getDeveloperList from "./GameDeveloper/GetDeveloperList";
import getGameDeveloperDetails from "./GameDeveloper/GetGameDeveloperDetails";
import activateGameDeveloper from "./GameDeveloper/ActivateGameDeveloper";
// Playground
import getPlayerRankings from "./PlaygroundS02/Leaderboard/PlayerRankings";
import getTeamRankings from "./PlaygroundS02/Leaderboard/TeamRankings";
import getPlayerFilter from "./PlaygroundS02/AdditionalPoints/GetPlayerFilter";
import getAdditionalPointsActivities from "./PlaygroundS02/AdditionalPoints/ListActivities";
import getAdditionalPoints from "./PlaygroundS02/AdditionalPoints/GetAdditionalPointsList";
import addNewAdditionalPoint from "./PlaygroundS02/AdditionalPoints/AddNewAdditionalPoint";
import addNewPlayer from "./PlaygroundS02/PlayerMaster/AddNewPlayer";
import getTeamLists from "./PlaygroundS02/PlayerMaster/GetTeamLists";
import switchTeam from "./PlaygroundS02/PlayerMaster/SwitchTeam";
import eliminatePlayer from "./PlaygroundS02/PlayerMaster/EliminatePlayer";
import getPlayersList from "./PlaygroundS02/PlayerMaster/GetPlayersList";
import getGameplayData from "./PlaygroundS02/PointsMaster/Gameplay";
import submitScores from "./PlaygroundS02/PointsMaster/SubmitScores";
import getVotings from "./PlaygroundS02/PointsMaster/Votings";
//storefront
import getAllProducts from "./Storefront/Products/GetAllProducts";
import addProductDetails from "./Storefront/Products/AddProductDetails";
import getProductDetailsById from "./Storefront/Products/GetProductDetailsById";
import addProductInventory from "./Storefront/Products/AddProductInventory";
import addProductImage from "./Storefront/Products/AddProductImage";
import getProductImagesById from "./Storefront/Products/GetProductImagesById";
import getAllCategoryDetails from "./Storefront/Category/GetAllCategoryDetails";
import getCategoryDetailsById from "./Storefront/Category/GetCategoryDetailsById";
import addCategoryDetails from "./Storefront/Category/AddCategoryDetails";
import addCategoryImage from "./Storefront/Category/AddCategoryImage";
import getAllCategoryImages from "./Storefront/Category/GetAllCategoryImages";
import updateCategoryBannerDetails from "./Storefront/Category/UpdateCategoryBannerDetails";
import deleteCategoryBanner from "./Storefront/Category/DeleteCategoryBanner";
import deleteProductImage from "./Storefront/Products/DeleteProductImage";
import updateProductImage from "./Storefront/Products/UpdateProductImage";
import activateProduct from "./Storefront/Products/ActivateProduct";
import getAllOrders from "./Storefront/Orders/GetAllOrders";
import updateOrder from "./Storefront/Orders/UpdateOrder";

//lobby programming

import getLobbyList from "./LobbyProgramming/GetLobbyList";
import getLobbyDetails from "./LobbyProgramming/GetLobbyDetails";
import createNewLobby from "./LobbyProgramming/CreateNewLobby";
import editLobby from "./LobbyProgramming/EditLobby";
import getTournamentCode from "./LobbyProgramming/GetTournamentCode";

export default combineReducers({
  login,
  logout,
  gamesFilter,
  getGameList,
  getGameId,
  uploadIcon,
  editGameDetails,
  gameDetailsById,
  linkHtmlGame,
  gameBanner,
  gameVideo,
  deleteBannerImage,
  deleteGameplayVideo,
  getFreeGameplays,
  activateFreeGameplay,
  deactivateFreeGameplay,
  getAllGameplayVideos,
  getAllGameplayBanners,
  changeBannerPriority,
  activateTournament,
  getActivatedTournaments,
  deactivateTournament,
  activateGame,
  createTournament,
  getTournamentList,
  editTournamentDetails,
  editRewards,
  getTournamentDetails,
  deleteRewardRank,
  deployTournament,
  getTournamentLoaderboard,
  setTab,
  createNewGameDeveloper,
  editGameDeveloper,
  getDeveloperList,
  getGameDeveloperDetails,
  activateGameDeveloper,
  addNewPlayer,
  getTeamLists,
  switchTeam,
  eliminatePlayer,
  getAdditionalPoints,
  addNewAdditionalPoint,
  getPlayerRankings,
  getTeamRankings,
  getAdditionalPointsActivities,
  getPlayersList,
  getPlayerFilter,
  getGameplayData,
  submitScores,
  getVotings,
  getAllProducts,
  addProductDetails,
  getProductDetailsById,
  addProductInventory,
  addProductImage,
  getProductImagesById,
  getAllCategoryDetails,
  getCategoryDetailsById,
  addCategoryDetails,
  addCategoryImage,
  getAllCategoryImages,
  updateCategoryBannerDetails,
  deleteCategoryBanner,
  deleteProductImage,
  updateProductImage,
  activateProduct,
  getAllOrders,
  updateOrder,
  getAdsList,
  getBrandsList,
  getCampaignsList,
  getBrandDetails,
  createNewBrand,
  getAdsDetails,
  getCampaignsDetails,
  createNewCampaign,
  createNewAds,
  getBrandCampaignsDetails,
  getAdsAssociatedDetails,
  getAllBrandsFiltered,
  getAllCampaignsFiltered,
  uploadMonetisationIcon,
  activateBrands,
  activateCampaigns,
  activateAds,
  editBrand,
  editCampaign,
  editAd,
  uploadMonetisationVideo,
  getAllAdSlotsFiltered,
  getAllAdSlotsMapping,
  updateAdSlotsMapping,
  getLobbyList,
  getLobbyDetails, 
  createNewLobby,
  editLobby,
  getTournamentCode,
});

export const LOGIN_INIT = "LOGIN_INIT";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGIN_RESET = "LOGIN_RESET";

export const LOGOUT_INIT = "LOGOUT_INIT";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILED = "LOGOUT_FAILED";
export const LOGOUT_RESET = "LOGOUT_RESET";

export const GAMES_FILTER_INIT = "GAMES_FILTER_INIT";
export const GAMES_FILTER_SUCCESS = "GAMES_FILTER_SUCCESS";
export const GAMES_FILTER_FAILED = "GAMES_FILTER_FAILED";
export const GAMES_FILTER_RESET = "GAMES_FILTER_RESET";

export const DAILY_TOURNAMENT = "DAILY_TOURNAMENT";
export const WEEKLY_TOURNAMENT = "WEEKLY_TOURNAMENT";
export const MONTHLY_TOURNAMENT = "MONTHLY_TOURNAMENT";
export const RESET_TOURNAMENT_MODE = "RESET_TOURNAMENT_MODE";

export const GET_GAME_LIST_INIT = "GET_GAME_LIST_INIT";
export const GET_GAME_LIST_SUCCESS = "GET_GAME_LIST_SUCCESS";
export const GET_GAME_LIST_FAILED = "GET_GAME_LIST_FAILED";
export const GET_GAME_LIST_RESET = "GET_GAME_LIST_RESET";

export const UPLOAD_ICON_INIT = "UPLOAD_ICON_INIT";
export const UPLOAD_ICON_SUCCESS = "UPLOAD_ICON_SUCCESS";
export const UPLOAD_ICON_FAILED = "UPLOAD_ICON_FAILED";
export const UPLOAD_ICON_RESET = "UPLOAD_ICON_RESET";

export const EDIT_GAME_DETAILS_INIT = "EDIT_GAME_DETAILS_INIT";
export const EDIT_GAME_DETAILS_SUCCESS = "EDIT_GAME_DETAILS_SUCCESS";
export const EDIT_GAME_DETAILS_FAILED = "EDIT_GAME_DETAILS_FAILED";
export const EDIT_GAME_DETAILS_RESET = "EDIT_GAME_DETAILS_RESET";

export const GET_GAME_DETAILS_BY_ID_INIT = "GET_GAME_DETAILS_BY_ID_INIT";
export const GET_GAME_DETAILS_BY_ID_SUCCESS = "GET_GAME_DETAILS_BY_ID_SUCCESS";
export const GET_GAME_DETAILS_BY_ID_FAILED = "GET_GAME_DETAILS_BY_ID_FAILED";
export const GET_GAME_DETAILS_BY_ID_RESET = "GET_GAME_DETAILS_BY_ID_RESET";

export const GET_GAME_ID_INIT = "GET_GAME_ID_INIT";
export const GET_GAME_ID_SUCCESS = "GET_GAME_ID_SUCCESS";
export const GET_GAME_ID_FAILED = "GET_GAME_ID_FAILED";
export const GET_GAME_ID_RESET = "GET_GAME_ID_RESET";

export const GET_GAMEPLAY_STATUS_INIT = "GET_GAMEPLAY_STATUS_INIT";
export const GET_GAMEPLAY_STATUS_SUCCESS = "GET_GAMEPLAY_STATUS_SUCCESS";
export const GET_GAMEPLAY_STATUS_FAILED = "GET_GAMEPLAY_STATUS_FAILED";
export const GET_GAMEPLAY_STATUS_RESET = "GET_GAMEPLAY_STATUS_RESET";

export const SET_FREE_GAMEPLAY_DATA = "SET_FREE_GAMEPLAY_DATA";
export const RESET_FREE_GAMEPLAY_DATA = "RESET_FREE_GAMEPLAY_DATA";

export const GET_FREE_GAMEPLAYS_INIT = "GET_FREE_GAMEPLAYS_INIT";
export const GET_FREE_GAMEPLAYS_SUCCESS = "GET_FREE_GAMEPLAYS_SUCCESS";
export const GET_FREE_GAMEPLAYS_FAILED = "GET_FREE_GAMEPLAYS_FAILED";
export const GET_FREE_GAMEPLAYS_RESET = "GET_FREE_GAMEPLAYS_RESET";

export const ACTIVATE_FREE_GAMEPLAY_INIT = "ACTIVATE_FREE_GAMEPLAY_INIT";
export const ACTIVATE_FREE_GAMEPLAY_SUCCESS = "ACTIVATE_FREE_GAMEPLAY_SUCCESS";
export const ACTIVATE_FREE_GAMEPLAY_FAILED = "ACTIVATE_FREE_GAMEPLAY_FAILED";
export const ACTIVATE_FREE_GAMEPLAY_RESET = "ACTIVATE_FREE_GAMEPLAY_RESET";

export const DEACTIVATE_FREEGAMEPLAY_INIT = "DEACTIVATE_FREEGAMEPLAY_INIT";
export const DEACTIVATE_FREEGAMEPLAY_SUCCESS = "DEACTIVATE_FREEGAMEPLAY_SUCCESS";
export const DEACTIVATE_FREEGAMEPLAY_FAILED = "DEACTIVATE_FREEGAMEPLAY_FAILED";
export const DEACTIVATE_FREEGAMEPLAY_RESET = "DEACTIVATE_FREEGAMEPLAY_RESET";

export const GET_ALL_GAME_PLAY_VIDEOS_INIT = "GET_ALL_GAME_PLAY_VIDEOS_INIT";
export const GET_ALL_GAME_PLAY_VIDEOS_SUCCESS = "GET_ALL_GAME_PLAY_VIDEOS_SUCCESS";
export const GET_ALL_GAME_PLAY_VIDEOS_FAILED = "GET_ALL_GAME_PLAY_VIDEOS_FAILED";
export const GET_ALL_GAME_PLAY_VIDEOS_RESET = "GET_ALL_GAME_PLAY_VIDEOS_RESET";

export const DELETE_GAMEPLAY_VIDEO_INIT = "DELETE_GAMEPLAY_VIDEO_INIT";
export const DELETE_GAMEPLAY_VIDEO_SUCCESS = "DELETE_GAMEPLAY_VIDEO_SUCCESS";
export const DELETE_GAMEPLAY_VIDEO_FAILED = "DELETE_GAMEPLAY_VIDEO_FAILED";
export const DELETE_GAMEPLAY_VIDEO_RESET = "DELETE_GAMEPLAY_VIDEO_RESET";

export const DELETE_BANNER_IMAGE_INIT = "DELETE_BANNER_IMAGE_INIT";
export const DELETE_BANNER_IMAGE_SUCCESS = "DELETE_BANNER_IMAGE_SUCCESS";
export const DELETE_BANNER_IMAGE_FAILED = "DELETE_BANNER_IMAGE_FAILED";
export const DELETE_BANNER_IMAGE_RESET = "DELETE_BANNER_IMAGE_RESET";

export const GET_ALL_GAME_PLAY_BANNERS_INIT = "GET_ALL_GAME_PLAY_BANNERS_INIT";
export const GET_ALL_GAME_PLAY_BANNERS_SUCCESS = "GET_ALL_GAME_PLAY_BANNERS_SUCCESS";
export const GET_ALL_GAME_PLAY_BANNERS_FAILED = "GET_ALL_GAME_PLAY_BANNERS_FAILED";
export const GET_ALL_GAME_PLAY_BANNERS_RESET = "GET_ALL_GAME_PLAY_BANNERS_RESET";

export const CHANGE_BANNER_PRIORITY_INIT = "CHANGE_BANNER_PRIORITY_INIT";
export const CHANGE_BANNER_PRIORITY_SUCCESS = "CHANGE_BANNER_PRIORITY_SUCCESS";
export const CHANGE_BANNER_PRIORITY_FAILED = "CHANGE_BANNER_PRIORITY_FAILED";
export const CHANGE_BANNER_PRIORITY_RESET = "CHANGE_BANNER_PRIORITY_RESET";

export const ACTIVATE_GAME_INIT = "ACTIVATE_GAME_INIT";
export const ACTIVATE_GAME_SUCCESS = "ACTIVATE_GAME_SUCCESS";
export const ACTIVATE_GAME_FAILED = "ACTIVATE_GAME_FAILED";
export const ACTIVATE_GAME_RESET = "ACTIVATE_GAME_RESET";

export const CREATE_TOURNAMENT_INIT = "CREATE_TOURNAMENT_INIT";
export const CREATE_TOURNAMENT_SUCCESS = "CREATE_TOURNAMENT_SUCCESS";
export const CREATE_TOURNAMENT_FAILED = "CREATE_TOURNAMENT_FAILED";
export const CREATE_TOURNAMENT_RESET = "CREATE_TOURNAMENT_RESET";

export const GET_TOURNAMENT_LIST_INIT = "GET_TOURNAMENT_LIST_INIT";
export const GET_TOURNAMENT_LIST_SUCCESS = "GET_TOURNAMENT_LIST_SUCCESS";
export const GET_TOURNAMENT_LIST_FAILED = "GET_TOURNAMENT_LIST_FAILED";
export const GET_TOURNAMENT_LIST_RESET = "GET_TOURNAMENT_LIST_RESET";

export const EDIT_TOURNAMENT_DETAILS_INIT = "EDIT_TOURNAMENT_DETAILS_INIT";
export const EDIT_TOURNAMENT_DETAILS_SUCCESS = "EDIT_TOURNAMENT_DETAILS_SUCCESS";
export const EDIT_TOURNAMENT_DETAILS_FAILED = "EDIT_TOURNAMENT_DETAILS_FAILED";
export const EDIT_TOURNAMENT_DETAILS_RESET = "EDIT_TOURNAMENT_DETAILS_RESET";

export const EDIT_REWARDS_INIT = "EDIT_REWARDS_INIT";
export const EDIT_REWARDS_SUCCESS = "EDIT_REWARDS_SUCCESS";
export const EDIT_REWARDS_FAILED = "EDIT_REWARDS_FAILED";
export const EDIT_REWARDS_RESET = "EDIT_REWARDS_RESET";

export const GET_TOURNAMENT_DETAILS_INIT = "GET_TOURNAMENT_DETAILS_INIT";
export const GET_TOURNAMENT_DETAILS_SUCCESS = "GET_TOURNAMENT_DETAILS_SUCCESS";
export const GET_TOURNAMENT_DETAILS_FAILED = "GET_TOURNAMENT_DETAILS_FAILED";
export const GET_TOURNAMENT_DETAILS_RESET = "GET_TOURNAMENT_DETAILS_RESET";

export const DELETE_REWARD_RANK_INIT = "DELETE_REWARD_RANK_INIT";
export const DELETE_REWARD_RANK_SUCCESS = "DELETE_REWARD_RANK_SUCCESS";
export const DELETE_REWARD_RANK_FAILED = "DELETE_REWARD_RANK_FAILED";
export const DELETE_REWARD_RANK_RESET = "DELETE_REWARD_RANK_RESET";

export const GET_TOURNAMENT_LOADERBOARD_INIT = "GET_TOURNAMENT_LOADERBOARD_INIT";
export const GET_TOURNAMENT_LOADERBOARD_SUCCESS = "GET_TOURNAMENT_LOADERBOARD_SUCCESS";
export const GET_TOURNAMENT_LOADERBOARD_FAILED = "GET_TOURNAMENT_LOADERBOARD_FAILED";
export const GET_TOURNAMENT_LOADERBOARD_RESET = "GET_TOURNAMENT_LOADERBOARD_RESET";

export const DEPLOY_TOURNAMENT_INIT = "DEPLOY_TOURNAMENT_INIT";
export const DEPLOY_TOURNAMENT_SUCCESS = "DEPLOY_TOURNAMENT_SUCCESS";
export const DEPLOY_TOURNAMENT_FAILED = "DEPLOY_TOURNAMENT_FAILED";
export const DEPLOY_TOURNAMENT_RESET = "DEPLOY_TOURNAMENT_RESET";

export const CREATE_NEW_GAME_DEVELOPER_INIT = "CREATE_NEW_GAME_DEVELOPER_INIT";
export const CREATE_NEW_GAME_DEVELOPER_SUCCESS = "CREATE_NEW_GAME_DEVELOPER_SUCCESS";
export const CREATE_NEW_GAME_DEVELOPER_FAILED = "CREATE_NEW_GAME_DEVELOPER_FAILED";
export const CREATE_NEW_GAME_DEVELOPER_RESET = "CREATE_NEW_GAME_DEVELOPER_RESET";

export const EDIT_GAME_DEVELOPER_INIT = "EDIT_GAME_DEVELOPER_INIT";
export const EDIT_GAME_DEVELOPER_SUCCESS = "EDIT_GAME_DEVELOPER_SUCCESS";
export const EDIT_GAME_DEVELOPER_FAILED = "EDIT_GAME_DEVELOPER_FAILED";
export const EDIT_GAME_DEVELOPER_RESET = "EDIT_GAME_DEVELOPER_RESET";

export const GET_GAME_DEVELOPER_DETAILS_INIT = "GET_GAME_DEVELOPER_DETAILS_INIT";
export const GET_GAME_DEVELOPER_DETAILS_SUCCESS = "GET_GAME_DEVELOPER_DETAILS_SUCCESS";
export const GET_GAME_DEVELOPER_DETAILS_FAILED = "GET_GAME_DEVELOPER_DETAILS_FAILED";
export const GET_GAME_DEVELOPER_DETAILS_RESET = "GET_GAME_DEVELOPER_DETAILS_RESET";

export const GET_DEVELOPER_LIST_INIT = "GET_DEVELOPER_LIST_INIT";
export const GET_DEVELOPER_LIST_SUCCESS = "GET_DEVELOPER_LIST_SUCCESS";
export const GET_DEVELOPER_LIST_FAILED = "GET_DEVELOPER_LIST_FAILED";
export const GET_DEVELOPER_LIST_RESET = "GET_DEVELOPER_LIST_RESET";

export const LINK_GAME_SUCCESS = "GAME_SUCCESS";
export const LINK_GAME_LOADING = "GAME_LOADING";
export const LINK_GAME_RESET = "GAME_RESET";
export const LINK_GAME_FAILED = "GAME_FAILED";

export const GAME_BANNER_INIT = "GAME_BANNER_INIT";
export const GAME_BANNER_SUCCESS = "GAME_BANNER_SUCCESS";
export const GAME_BANNER_FAILED = "GAME_BANNER_FAILED";
export const GAME_BANNER_RESET = "GAME_BANNER_RESET";

export const GAME_VIDEO_INIT = "GAME_VIDEO_INIT";
export const GAME_VIDEO_SUCCESS = "GAME_VIDEO_SUCCESS";
export const GAME_VIDEO_FAILED = "GAME_VIDEO_FAILED";
export const GAME_VIDEO_RESET = "GAME_VIDEO_RESET";

export const ACTIVATE_TOURNAMENT_INIT = "ACTIVATE_TOURNAMENT_INIT";
export const ACTIVATE_TOURNAMENT_SUCCESS = "ACTIVATE_TOURNAMENT_SUCCESS";
export const ACTIVATE_TOURNAMENT_FAILED = "ACTIVATE_TOURNAMENT_FAILED";
export const ACTIVATE_TOURNAMENT_RESET = "ACTIVATE_TOURNAMENT_RESET";

export const GET_ACTIVATED_TOURNAMENTS_INIT = "GET_ACTIVATED_TOURNAMENTS_INIT";
export const GET_ACTIVATED_TOURNAMENTS_SUCCESS = "GET_ACTIVATED_TOURNAMENTS_SUCCESS";
export const GET_ACTIVATED_TOURNAMENTS_FAILED = "GET_ACTIVATED_TOURNAMENTS_FAILED";
export const GET_ACTIVATED_TOURNAMENTS_RESET = "GET_ACTIVATED_TOURNAMENTS_RESET";

export const DEACTIVATE_TOURNAMENTS_INIT = "DEACTIVATE_TOURNAMENTS_INIT";
export const DEACTIVATE_TOURNAMENTS_SUCCESS = "DEACTIVATE_TOURNAMENTS_SUCCESS";
export const DEACTIVATE_TOURNAMENTS_FAILED = "DEACTIVATE_TOURNAMENTS_FAILED";
export const DEACTIVATE_TOURNAMENTS_RESET = "DEACTIVATE_TOURNAMENTS_RESET";

export const ACTIVATE_GAMEDEVELOPER_INIT = "ACTIVATE_GAMEDEVELOPER_INIT";
export const ACTIVATE_GAMEDEVELOPER_SUCCESS = "ACTIVATE_GAMEDEVELOPER_SUCCESS";
export const ACTIVATE_GAMEDEVELOPER_FAILED = "ACTIVATE_GAMEDEVELOPER_FAILED";
export const ACTIVATE_GAMEDEVELOPER_RESET = "ACTIVATE_GAMEDEVELOPER_RESET";

export const ADD_NEW_PLAYER_INIT = "ADD_NEW_PLAYER_INIT";
export const ADD_NEW_PLAYER_SUCCESS = "ADD_NEW_PLAYER_SUCCESS";
export const ADD_NEW_PLAYER_FAILED = "ADD_NEW_PLAYER_FAILED";
export const ADD_NEW_PLAYER_RESET = "ADD_NEW_PLAYER_RESET";

export const SWITCH_TEAM_INIT = "SWITCH_TEAM_INIT";
export const SWITCH_TEAM_SUCCESS = "SWITCH_TEAM_SUCCESS";
export const SWITCH_TEAM_FAILED = "SWITCH_TEAM_FAILED";
export const SWITCH_TEAM_RESET = "SWITCH_TEAM_RESET";

export const ELIMINATE_PLAYER_INIT = "ELIMINATE_PLAYER_INIT";
export const ELIMINATE_PLAYER_SUCCESS = "ELIMINATE_PLAYER_SUCCESS";
export const ELIMINATE_PLAYER_FAILED = "ELIMINATE_PLAYER_FAILED";
export const ELIMINATE_PLAYER_RESET = "ELIMINATE_PLAYER_RESET";

export const ADD_NEW_ENTRY_INIT = "ADD_NEW_ENTRY_INIT";
export const ADD_NEW_ENTRY_SUCCESS = "ADD_NEW_ENTRY_SUCCESS";
export const ADD_NEW_ENTRY_FAILED = "ADD_NEW_ENTRY_FAILED ";
export const ADD_NEW_ENTRY_RESET = "ADD_NEW_ENTRY_RESET";

export const GET_ADDITIONAL_POINTS_LIST_INIT = "GET_ADDITIONAL_POINTS_LIST_INIT";
export const GET_ADDITIONAL_POINTS_LIST_SUCCESS = "GET_ADDITIONAL_POINTS_LIST_SUCCESS";
export const GET_ADDITIONAL_POINTS_LIST_FAILED = "GET_ADDITIONAL_POINTS_LIST_FAILED";
export const GET_ADDITIONAL_POINTS_LIST_RESET = "GET_ADDITIONAL_POINTS_LIST_RESET";

export const ADD_ADDITIONAL_POINTS_INIT = "ADD_ADDITIONAL_POINTS_INIT";
export const ADD_ADDITIONAL_POINTS_SUCCESS = "ADD_ADDITIONAL_POINTS_SUCCESS";
export const ADD_ADDITIONAL_POINTS_FAILED = "ADD_ADDITIONAL_POINTS_FAILED";
export const ADD_ADDITIONAL_POINTS_RESET = "ADD_ADDITIONAL_POINTS_RESET";

export const GET_PLAYER_RANKINGS_INIT = "GET_PLAYER_RANKINGS_INIT";
export const GET_PLAYER_RANKINGS_SUCCESS = "GET_PLAYER_RANKINGS_SUCCESS";
export const GET_PLAYER_RANKINGS_FAILED = "GET_PLAYER_RANKINGS_FAILED";
export const GET_PLAYER_RANKINGS_RESET = "GET_PLAYER_RANKINGS_RESET";

export const GET_TEAM_RANKINGS_INIT = "GET_TEAM_RANKINGS_INIT";
export const GET_TEAM_RANKINGS_SUCCESS = "GET_TEAM_RANKINGS_SUCCESS";
export const GET_TEAM_RANKINGS_FAILED = "GET_TEAM_RANKINGS_FAILED";
export const GET_TEAM_RANKINGS_RESET = "GET_TEAM_RANKINGS_RESET";

export const GET_TEAM_LISTS_INIT = "GET_TEAM_LISTS_INIT";
export const GET_TEAM_LISTS_SUCCESS = "GET_TEAM_LISTS_SUCCESS";
export const GET_TEAM_LISTS_FAILED = "GET_TEAM_LISTS_FAILED";
export const GET_TEAM_LISTS_RESET = "GET_TEAM_LISTS_RESET";

export const GET_PLAYER_LIST_INIT = "GET_PLAYER_LIST_INIT";
export const GET_PLAYER_LIST_SUCCESS = "GET_PLAYER_LIST_SUCCESS";
export const GET_PLAYER_LIST_FAILED = "GET_PLAYER_LIST_FAILED";
export const GET_PLAYER_LIST_RESET = "GET_PLAYER_LIST_RESET";

export const GET_PLAYER_FILTER_INIT = "GET_PLAYER_FILTER_INIT";
export const GET_PLAYER_FILTER_SUCCESS = "GET_PLAYER_FILTER_SUCCESS";
export const GET_PLAYER_FILTER_FAILED = "GET_PLAYER_FILTER_FAILED";
export const GET_PLAYER_FILTER_RESET = "GET_PLAYER_FILTER_RESET";

export const GET_ADDITIONAL_POINTS_ACTIVITIES_INIT = "GET_ADDITIONAL_POINTS_ACTIVITIES_INIT";
export const GET_ADDITIONAL_POINTS_ACTIVITIES_SUCCESS = "GET_ADDITIONAL_POINTS_ACTIVITIES_SUCCESS";
export const GET_ADDITIONAL_POINTS_ACTIVITIES_FAILED = "GET_ADDITIONAL_POINTS_ACTIVITIES_FAILED";
export const GET_ADDITIONAL_POINTS_ACTIVITIES_RESET = "GET_ADDITIONAL_POINTS_ACTIVITIES_RESET";

export const GET_GAMEPLAY_DATA_INIT = "GET_GAMEPLAY_DATA_INIT";
export const GET_GAMEPLAY_DATA_SUCCESS = "GET_GAMEPLAY_DATA_SUCCESS";
export const GET_GAMEPLAY_DATA_FAILED = "GET_GAMEPLAY_DATA_FAILED";
export const GET_GAMEPLAY_DATA_RESET = "GET_GAMEPLAY_DATA_RESET";

export const SUBMIT_SCORES_INIT = "SUBMIT_SCORES_INIT";
export const SUBMIT_SCORES_SUCCESS = "SUBMIT_SCORES_SUCCESS";
export const SUBMIT_SCORES_FAILED = "SUBMIT_SCORES_FAILED";
export const SUBMIT_SCORES_RESET = "SUBMIT_SCORES_RESET";

export const GET_VOTINGS_INIT = "GET_VOTINGS_INIT";
export const GET_VOTINGS_SUCCESS = "GET_VOTINGS_SUCCESS";
export const GET_VOTINGS_FAILED = "GET_VOTINGS_FAILED";
export const GET_VOTINGS_RESET = "GET_VOTINGS_RESET";

// STOREFRONT

export const GET_PRODUCT_FILTERS_INIT = "GET_PRODUCT_FILTERS_INIT";
export const GET_PRODUCT_FILTERS_SUCCESS = "GET_PRODUCT_FILTERS_SUCCESS";
export const GET_PRODUCT_FILTERS_FAILED = "GET_PRODUCT_FILTERS_FAILED";
export const GET_PRODUCT_FILTERS_RESET = "GET_PRODUCT_FILTERS_RESET";

export const ADD_PRODUCT_DETAILS_INIT = "ADD_PRODUCT_DETAILS_INIT";
export const ADD_PRODUCT_DETAILS_SUCCESS = "ADD_PRODUCT_DETAILS_SUCCESS";
export const ADD_PRODUCT_DETAILS_FAILED = "ADD_PRODUCT_DETAILS_FAILED";
export const ADD_PRODUCT_DETAILS_RESET = "ADD_PRODUCT_DETAILS_RESET";

export const GET_PRODUCT_DETAILS_BY_ID_INIT = "GET_PRODUCT_DETAILS_BY_ID_INIT";
export const GET_PRODUCT_DETAILS_BY_ID_SUCCESS = "GET_PRODUCT_DETAILS_BY_ID_SUCCESS";
export const GET_PRODUCT_DETAILS_BY_ID_FAILED = "GET_PRODUCT_DETAILS_BY_ID_FAILED";
export const GET_PRODUCT_DETAILS_BY_ID_RESET = "GET_PRODUCT_DETAILS_BY_ID_RESET";

export const ADD_PRODUCT_INVENTORY_INIT = "ADD_PRODUCT_INVENTORY_INIT";
export const ADD_PRODUCT_INVENTORY_SUCCESS = "ADD_PRODUCT_INVENTORY_SUCCESS";
export const ADD_PRODUCT_INVENTORY_FAILED = "ADD_PRODUCT_INVENTORY_FAILED";
export const ADD_PRODUCT_INVENTORY_RESET = "ADD_PRODUCT_INVENTORY_RESET";

export const ADD_PRODUCT_IMAGE_INIT = "ADD_PRODUCT_IMAGE_INIT";
export const ADD_PRODUCT_IMAGE_SUCCESS = "ADD_PRODUCT_IMAGE_SUCCESS";
export const ADD_PRODUCT_IMAGE_FAILED = "ADD_PRODUCT_IMAGE_FAILED";
export const ADD_PRODUCT_IMAGE_RESET = "ADD_PRODUCT_IMAGE_RESET";

export const GET_PRODUCT_IMAGES_BY_ID_INIT = "GET_PRODUCT_IMAGES_BY_ID_INIT";
export const GET_PRODUCT_IMAGES_BY_ID_SUCCESS = "GET_PRODUCT_IMAGES_BY_ID_SUCCESS";
export const GET_PRODUCT_IMAGES_BY_ID_FAILED = "GET_PRODUCT_IMAGES_BY_ID_FAILED";
export const GET_PRODUCT_IMAGES_BY_ID_RESET = "GET_PRODUCT_IMAGES_BY_ID_RESET";

export const GET_ALL_CATEGORY_DETAILS_INIT = "GET_ALL_CATEGORY_DETAILS_INIT";
export const GET_ALL_CATEGORY_DETAILS_SUCCESS = "GET_ALL_CATEGORY_DETAILS_SUCCESS";
export const GET_ALL_CATEGORY_DETAILS_FAILED = "GET_ALL_CATEGORY_DETAILS_FAILED";
export const GET_ALL_CATEGORY_DETAILS_RESET = "GET_ALL_CATEGORY_DETAILS_RESET";

export const GET_CATEGORY_DETAILS_BY_ID_INIT = "GET_CATEGORY_DETAILS_BY_ID_INIT";
export const GET_CATEGORY_DETAILS_BY_ID_SUCCESS = "GET_CATEGORY_DETAILS_BY_ID_SUCCESS";
export const GET_CATEGORY_DETAILS_BY_ID_FAILED = "GET_CATEGORY_DETAILS_BY_ID_FAILED";
export const GET_CATEGORY_DETAILS_BY_ID_RESET = "GET_CATEGORY_DETAILS_BY_ID_RESET";

export const ADD_CATEGORY_DETAILS_INIT = "ADD_CATEGORY_DETAILS_INIT";
export const ADD_CATEGORY_DETAILS_SUCCESS = "ADD_CATEGORY_DETAILS_SUCCESS";
export const ADD_CATEGORY_DETAILS_FAILED = "ADD_CATEGORY_DETAILS_FAILED";
export const ADD_CATEGORY_DETAILS_RESET = "ADD_CATEGORY_DETAILS_RESET";

export const ADD_CATEGORY_IMAGE_INIT = "ADD_CATEGORY_IMAGE_INIT";
export const ADD_CATEGORY_IMAGE_SUCCESS = "ADD_CATEGORY_IMAGE_SUCCESS";
export const ADD_CATEGORY_IMAGE_FAILED = "ADD_CATEGORY_IMAGE_FAILED";
export const ADD_CATEGORY_IMAGE_RESET = "ADD_CATEGORY_IMAGE_RESET";

export const GET_ALL_CATEGORY_IMAGES_INIT = "GET_ALL_CATEGORY_IMAGES_INIT";
export const GET_ALL_CATEGORY_IMAGES_SUCCESS = "GET_ALL_CATEGORY_IMAGES_SUCCESS";
export const GET_ALL_CATEGORY_IMAGES_FAILED = "GET_ALL_CATEGORY_IMAGES_FAILED";
export const GET_ALL_CATEGORY_IMAGES_RESET = "GET_ALL_CATEGORY_IMAGES_RESET";

export const UPDATE_CATEGORY_BANNER_DETAILS_INIT = "UPDATE_CATEGORY_BANNER_DETAILS_INIT";
export const UPDATE_CATEGORY_BANNER_DETAILS_SUCCESS = "UPDATE_CATEGORY_BANNER_DETAILS_SUCCESS";
export const UPDATE_CATEGORY_BANNER_DETAILS_FAILED = "UPDATE_CATEGORY_BANNER_DETAILS_FAILED";
export const UPDATE_CATEGORY_BANNER_DETAILS_RESET = "UPDATE_CATEGORY_BANNER_DETAILS_RESET";

export const DELETE_CATEGORY_BANNER_INIT = "DELETE_CATEGORY_BANNER_INIT";
export const DELETE_CATEGORY_BANNER_SUCCESS = "DELETE_CATEGORY_BANNER_SUCCESS";
export const DELETE_CATEGORY_BANNER_FAILED = "DELETE_CATEGORY_BANNER_FAILED";
export const DELETE_CATEGORY_BANNER_RESET = "DELETE_CATEGORY_BANNER_RESET";

export const DELETE_PRODUCT_IMAGE_INIT = "DELETE_PRODUCT_IMAGE_INIT";
export const DELETE_PRODUCT_IMAGE_SUCCESS = "DELETE_PRODUCT_IMAGE_SUCCESS";
export const DELETE_PRODUCT_IMAGE_FAILED = "DELETE_PRODUCT_IMAGE_FAILED";
export const DELETE_PRODUCT_IMAGE_RESET = "DELETE_PRODUCT_IMAGE_RESET";

export const UPDATE_PRODUCT_IMAGE_INIT = "UPDATE_PRODUCT_IMAGE_INIT";
export const UPDATE_PRODUCT_IMAGE_SUCCESS = "UPDATE_PRODUCT_IMAGE_SUCCESS";
export const UPDATE_PRODUCT_IMAGE_FAILED = "UPDATE_PRODUCT_IMAGE_FAILED";
export const UPDATE_PRODUCT_IMAGE_RESET = "UPDATE_PRODUCT_IMAGE_RESET";

export const ACTIVATE_PRODUCT_INIT = "ACTIVATE_PRODUCT_INIT";
export const ACTIVATE_PRODUCT_SUCCESS = "ACTIVATE_PRODUCT_SUCCESS";
export const ACTIVATE_PRODUCT_FAILED = "ACTIVATE_PRODUCT_FAILED";
export const ACTIVATE_PRODUCT_RESET = "ACTIVATE_PRODUCT_RESET";

export const GET_ALL_PRODUCTS_INIT = "GET_ALL_PRODUCTS_INIT";
export const GET_ALL_PRODUCTS_SUCCESS = "GET_ALL_PRODUCTS_SUCCESS";
export const GET_ALL_PRODUCTS_FAILED = "GET_ALL_PRODUCTS_FAILED";
export const GET_ALL_PRODUCTS_RESET = "GET_ALL_PRODUCTS_RESET";

export const GET_ALL_ORDERS_INIT = "GET_ALL_ORDERS_INIT";
export const GET_ALL_ORDERS_SUCCESS = "GET_ALL_ORDERS_SUCCESS";
export const GET_ALL_ORDERS_FAILED = "GET_ALL_ORDERS_FAILED";
export const GET_ALL_ORDERS_RESET = "GET_ALL_ORDERS_RESET";

export const UPDATE_ORDERS_INIT = "UPDATE_ORDERS_INIT";
export const UPDATE_ORDERS_SUCCESS = "UPDATE_ORDERS_SUCCESS";
export const UPDATE_ORDERS_FAILED = "UPDATE_ORDERS_FAILED";
export const UPDATE_ORDERS_RESET = "UPDATE_ORDERS_RESET";


export const GET_ADS_LIST_INIT = "GET_ADS_LIST_INIT";
export const GET_ADS_LIST_SUCCESS = "GET_ADS_LIST_SUCCESS";
export const GET_ADS_LIST_FAILED = "GET_ADS_LIST_FAILED";
export const GET_ADS_LIST_RESET = "GET_ADS_LIST_RESET";

 
export const GET_BRANDS_LIST_INIT = "GET_BRANDS_LIST_INIT";
export const GET_BRANDS_LIST_SUCCESS = "GET_BRANDS_LIST_SUCCESS";
export const GET_BRANDS_LIST_FAILED = "GET_BRANDS_LIST_FAILED";
export const GET_BRANDS_LIST_RESET = "GET_BRANDS_LIST_RESET";


export const GET_ADCAMPAIGNS_LIST_INIT = "GET_ADCAMPAIGNS_LIST_INIT";
export const GET_ADCAMPAIGNS_LIST_SUCCESS = "GET_ADCAMPAIGNS_LIST_SUCCESS";
export const GET_ADCAMPAIGNS_LIST_FAILED = "GET_ADCAMPAIGNS_LIST_FAILED";
export const GET_ADCAMPAIGNS_LIST_RESET = "GET_ADCAMPAIGNS_LIST_RESET";

export const GET_BRAND_DETAILS_INIT = "GET_BRAND_DETAILS_INIT";
export const GET_BRAND_DETAILS_SUCCESS = "GET_BRAND_DETAILS_SUCCESS";
export const GET_BRAND_DETAILS_FAILED = "GET_BRAND_DETAILS_FAILED";
export const GET_BRAND_DETAILS_RESET = "GET_BRAND_DETAILS_RESET";

export const CREATE_NEW_BRAND_INIT = "CREATE_NEW_BRAND_INIT";
export const CREATE_NEW_BRAND_SUCCESS = "CREATE_NEW_BRAND_SUCCESS";
export const CREATE_NEW_BRAND_FAILED = "CREATE_NEW_BRAND_FAILED";
export const CREATE_NEW_BRAND_RESET = "CREATE_NEW_BRAND_RESET";

export const ACTIVATE_CAMPAIGN_INIT = "ACTIVATE_CAMPAIGN_INIT";
export const ACTIVATE_CAMPAIGN_SUCCESS = "ACTIVATE_CAMPAIGN_SUCCESS";
export const ACTIVATE_CAMPAIGN_FAILED = "ACTIVATE_CAMPAIGN_FAILED";
export const ACTIVATE_CAMPAIGN_RESET = "ACTIVATE_CAMPAIGN_RESET";

export const ACTIVATE_BRAND_INIT = "ACTIVATE_BRAND_INIT";
export const ACTIVATE_BRAND_SUCCESS = "ACTIVATE_BRAND_SUCCESS";
export const ACTIVATE_BRAND_FAILED = "ACTIVATE_BRAND_FAILED";
export const ACTIVATE_BRAND_RESET = "ACTIVATE_BRAND_RESET";

export const ACTIVATE_ADS_INIT = "ACTIVATE_ADS_INIT";
export const ACTIVATE_ADS_SUCCESS = "ACTIVATE_ADS_SUCCESS";
export const ACTIVATE_ADS_FAILED = "ACTIVATE_ADS_FAILED";
export const ACTIVATE_ADS_RESET = "ACTIVATE_ADS_RESET";

export const GET_ADS_DETAILS_INIT = "GET_ADS_DETAILS_INIT";
export const GET_ADS_DETAILS_SUCCESS = "GET_ADS_DETAILS_SUCCESS";
export const GET_ADS_DETAILS_FAILED = "GET_ADS_DETAILS_FAILED";
export const GET_ADS_DETAILS_RESET = "GET_ADS_DETAILS_RESET";

export const GET_BRANDS_DETAILS_INIT = "GET_BRANDS_DETAILS_INIT";
export const GET_BRANDS_DETAILS_SUCCESS = "GET_BRANDS_DETAILS_SUCCESS";
export const GET_BRANDS_DETAILS_FAILED = "GET_BRANDS_DETAILS_FAILED";
export const GET_BRANDS_DETAILS_RESET = "GET_BRANDS_DETAILS_RESET";

export const GET_BRAND_CAMPAIGNS_DETAILS_INIT = "GET_BRAND_CAMPAIGN_DETAILS_INIT";
export const GET_BRAND_CAMPAIGNS_DETAILS_SUCCESS = "GET_BRAND_CAMPAIGN_DETAILS_SUCCESS";
export const GET_BRAND_CAMPAIGNS_DETAILS_FAILED = "GET_BRAND_CAMPAIGN_DETAILS_FAILED";
export const GET_BRAND_CAMPAIGNS_DETAILS_RESET = "GET_BRAND_CAMPAIGN_DETAILS_RESET";

export const GET_ADS_ASSOCIATED_DETAILS_INIT="GET_ADS_ASSOCIATED_DETAILS_INIT";
export const GET_ADS_ASSOCIATED_DETAILS_SUCCESS="GET_ADS_ASSOCIATED_DETAILS_SUCCESS";
export const GET_ADS_ASSOCIATED_DETAILS_FAILED="GET_ADS_ASSOCIATED_DETAILS_FAILED";
export const GET_ADS_ASSOCIATED_DETAILS_RESET="GET_ADS_ASSOCIATED_DETAILS_RESET";


export const GET_CAMPAIGNS_DETAILS_INIT = "GET_CAMPAIGNS_DETAILS_INIT";
export const GET_CAMPAIGNS_DETAILS_SUCCESS = "GET_CAMPAIGNS_DETAILS_SUCCESS";
export const GET_CAMPAIGNS_DETAILS_FAILED = "GET_CAMPAIGNS_DETAILS_FAILED";
export const GET_CAMPAIGNS_DETAILS_RESET = "GET_CAMPAIGNS_DETAILS_RESET";

export const CREATE_NEW_CAMPAIGN_INIT = "CREATE_NEW_CAMPAIGN_INIT";
export const CREATE_NEW_CAMPAIGN_SUCCESS = "CREATE_NEW_CAMPAIGN_SUCCESS";
export const CREATE_NEW_CAMPAIGN_FAILED = "CREATE_NEW_CAMPAIGN_FAILED";
export const CREATE_NEW_CAMPAIGN_RESET = "CREATE_NEW_CAMPAIGN_RESET";

export const CREATE_NEW_ADS_INIT = "CREATE_NEW_ADS_INIT";
export const CREATE_NEW_ADS_SUCCESS = "CREATE_NEW_ADS_SUCCESS";
export const CREATE_NEW_ADS_FAILED = "CREATE_NEW_ADS_FAILED";
export const CREATE_NEW_ADS_RESET = "CREATE_NEW_ADS_RESET";

export const GET_ALL_BRANDS_FILTERED_INIT = "GET_ALL_BRANDS_FILTERED_INIT";
export const GET_ALL_BRANDS_FILTERED_SUCCESS = "GET_ALL_BRANDS_FILTERED_SUCCESS";
export const GET_ALL_BRANDS_FILTERED_FAILED = "GET_ALL_BRANDS_FILTERED_FAILED";
export const GET_ALL_BRANDS_FILTERED_RESET = "GET_ALL_BRANDS_FILTERED_RESET";

export const GET_ALL_CAMPAIGNS_FILTERED_INIT = "GET_ALL_CAMPAIGNS_FILTERED_INIT";
export const GET_ALL_CAMPAIGNS_FILTERED_SUCCESS = "GET_ALL_CAMPAIGNS_FILTERED_SUCCESS";
export const GET_ALL_CAMPAIGNS_FILTERED_FAILED = "GET_ALL_CAMPAIGNS_FILTERED_FAILED";
export const GET_ALL_CAMPAIGNS_FILTERED_RESET = "GET_ALL_CAMPAIGNS_FILTERED_RESET";

export const UPLOAD_MONETISATION_ICON_INIT = "UPLOAD_MONETISATION_ICON_INIT";
export const UPLOAD_MONETISATION_ICON_SUCCESS = "UPLOAD_MONETISATION_ICON_SUCCESS";
export const UPLOAD_MONETISATION_ICON_FAILED = "UPLOAD_MONETISATION_ICON_FAILED";
export const UPLOAD_MONETISATION_ICON_RESET = "UPLOAD_MONETISATION_ICON_RESET";

export const UPLOAD_MONETISATION_VIDEO_INIT = "UPLOAD_MONETISATION_VIDEO_INIT";
export const UPLOAD_MONETISATION_VIDEO_SUCCESS = "UPLOAD_MONETISATION_VIDEO_SUCCESS";
export const UPLOAD_MONETISATION_VIDEO_FAILED = "UPLOAD_MONETISATION_VIDEO_FAILED";
export const UPLOAD_MONETISATION_VIDEO_RESET = "UPLOAD_MONETISATION_VIDEO_RESET";

export const ACTIVATE_BRANDS_INIT = "ACTIVATE_BRANDS_INIT";
export const ACTIVATE_BRANDS_SUCCESS = "ACTIVATE_BRANDS_SUCCESS";
export const ACTIVATE_BRANDS_FAILED = "ACTIVATE_BRANDS_FAILED";
export const ACTIVATE_BRANDS_RESET = "ACTIVATE_BRANDS_RESET";

export const ACTIVATE_CAMPAIGNS_INIT = "ACTIVATE_CAMPAIGNS_INIT";
export const ACTIVATE_CAMPAIGNS_SUCCESS = "ACTIVATE_CAMPAIGNS_SUCCESS";
export const ACTIVATE_CAMPAIGNS_FAILED = "ACTIVATE_CAMPAIGNS_FAILED";
export const ACTIVATE_CAMPAIGNS_RESET = "ACTIVATE_CAMPAIGNS_RESET";

export const EDIT_BRAND_INIT= "EDIT_BRAND_INIT";
export const EDIT_BRAND_SUCCESS= "EDIT_BRAND_SUCCESS";
export const EDIT_BRAND_FAILED= "EDIT_BRAND_FAILED";
export const EDIT_BRAND_RESET= "EDIT_BRAND_RESET";

export const EDIT_CAMPAIGN_INIT= "EDIT_CAMPAIGN_INIT";
export const EDIT_CAMPAIGN_SUCCESS= "EDIT_CAMPAIGN_SUCCESS";
export const EDIT_CAMPAIGN_FAILED= "EDIT_CAMPAIGN_FAILED";
export const EDIT_CAMPAIGN_RESET= "EDIT_CAMPAIGN_RESET";

export const EDIT_AD_INIT= "EDIT_AD_INIT";
export const EDIT_AD_SUCCESS= "EDIT_AD_SUCCESS";
export const EDIT_AD_FAILED= "EDIT_AD_FAILED";
export const EDIT_AD_RESET= "EDIT_AD_RESET";

export const GET_ALL_AD_SLOTS_FILTERED_INIT= "GET_ALL_AD_SLOTS_FILTERED_NIT";
export const GET_ALL_AD_SLOTS_FILTERED_SUCCESS= "GET_ALL_AD_SLOTS_FILTERED_SUCCESS";
export const GET_ALL_AD_SLOTS_FILTERED_FAILED= "GET_ALL_AD_SLOTS_FILTERED_FAILED";
export const GET_ALL_AD_SLOTS_FILTERED_RESET= "GET_ALL_AD_SLOTS_FILTERED_RESET";


export const GET_LOBBY_LIST_INIT = "GET_LOBBY_LIST_INIT";
export const GET_LOBBY_LIST_SUCCESS = "GET_LOBBY_LIST_SUCCESS";
export const GET_LOBBY_LIST_FAILED = "GET_LOBBY_LIST_FAILED";
export const GET_LOBBY_LIST_RESET = "GET_LOBBY_LIST_RESET";

export const UPDATE_AD_SLOTS_MAPPING_INIT="UPDATE_AD_SLOTS_MAPPING_INIT";
export const UPDATE_AD_SLOTS_MAPPING_SUCCESS="UPDATE_AD_SLOTS_MAPPING_SUCCESS";
export const UPDATE_AD_SLOTS_MAPPING_FAILED="UPDATE_AD_SLOTS_MAPPING_FAILED";
export const UPDATE_AD_SLOTS_MAPPING_RESET="UPDATE_AD_SLOTS_MAPPING_RESET";

export const GET_ALL_AD_SLOTS_INIT="GET_ALL_AD_SLOTS_INIT";
export const GET_ALL_AD_SLOTS_SUCCESS="GET_ALL_AD_SLOTS_SUCCESS";
export const GET_ALL_AD_SLOTS_FAILED="GET_ALL_AD_SLOTS_FAILED";
export const GET_ALL_AD_SLOTS_RESET="GET_ALL_AD_SLOTS_RESET";

export const GET_LOBBY_DETAILS_INIT = "GET_LOBBY_DETAILS_INIT";
export const GET_LOBBY_DETAILS_SUCCESS = "GET_LOBBY_DETAILS_SUCCESS";
export const GET_LOBBY_DETAILS_FAILED = "GET_LOBBY_DETAILS_FAILED";
export const GET_LOBBY_DETAILS_RESET = "GET_LOBBY_DETAILS_RESET";

export const CREATE_NEW_LOBBY_INIT = "CREATE_NEW_LOBBY_INIT";
export const CREATE_NEW_LOBBY_SUCCESS = "CREATE_NEW_LOBBY_SUCCESS";
export const CREATE_NEW_LOBBY_FAILED = "CREATE_NEW_LOBBY_FAILED";
export const CREATE_NEW_LOBBY_RESET = "CREATE_NEW_LOBBY_RESET";

export const EDIT_LOBBY_INIT= "EDIT_LOBBY_INIT";
export const EDIT_LOBBY_SUCCESS= "EDIT_LOBBY_SUCCESS";
export const EDIT_LOBBY_FAILED= "EDIT_LOBBY_FAILED";
export const EDIT_LOBBY_RESET= "EDIT_LOBBY_RESET";

export const TOURNAMENT_CODE_FILTER_INIT= "TOURNAMENT_CODE_FILTER_INIT";
export const TOURNAMENT_CODE_FILTER_SUCCESS= "TOURNAMENT_CODE_FILTER_SUCCESS";
export const TOURNAMENT_CODE_FILTER_FAILED= "TOURNAMENT_CODE_FILTER_FAILED";
export const TOURNAMENT_CODE_FILTER_RESET= "TOURNAMENT_CODE_FILTER_RESET";

export const LOBBY_FILTER_INIT = "LOBBY_FILTER_INIT";
export const LOBBY_FILTER_SUCCESS = "LOBBY_FILTER_SUCCESS";
export const LOBBY_FILTER_FAILED = "LOBBY_FILTER_FAILED";
export const LOBBY_FILTER_RESET = "LOBBY_FILTER_RESET";
import {
  GET_ACTIVATED_TOURNAMENTS_INIT,
  GET_ACTIVATED_TOURNAMENTS_SUCCESS,
  GET_ACTIVATED_TOURNAMENTS_FAILED,
  GET_ACTIVATED_TOURNAMENTS_RESET,
} from "../../../constants";

const initialState = {
  loading: false,
  data: {},
  code: "",
  success: false,
  error: "",
};

const getActivatedTournaments = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_ACTIVATED_TOURNAMENTS_INIT:
      return {
        ...state,
        loading: true,
      };
    case GET_ACTIVATED_TOURNAMENTS_SUCCESS:
      let payload = action.payload;
      let newArr = [];
      action.payload?.data?.tournamentResponses?.forEach((item, index) => {
        newArr.push({
          ...item,
          id: index + Math.random() * 1000,
        });
      });
      payload.data.tournamentResponses = newArr;
      return {
        ...state,
        loading: false,
        code: action.payload.code,
        success: action.payload.success,
        data: action.payload.data,
        error: "",
      };
    case GET_ACTIVATED_TOURNAMENTS_FAILED:
      return {
        ...state,
        loading: false,
        code: action.payload.code || action.payload.status,
        success: action.payload.success || false,
        data: {},
        error: action.payload.message || action.payload.data || action.payload.error,
      };

    case GET_ACTIVATED_TOURNAMENTS_RESET:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default getActivatedTournaments;

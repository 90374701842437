import {
    CREATE_NEW_CAMPAIGN_INIT,
    CREATE_NEW_CAMPAIGN_SUCCESS,
    CREATE_NEW_CAMPAIGN_FAILED,
    CREATE_NEW_CAMPAIGN_RESET,
  } from "../../../constants";

  const initialState = {
    loading: false,
    data: {},
    code: "",
    success: false,
    error: "",
  };
  
  const createNewCampaign = (state = initialState, action = {}) => {
    switch (action.type) {
      case CREATE_NEW_CAMPAIGN_INIT:
        return {
          ...state,
          loading: true,
        };
      case CREATE_NEW_CAMPAIGN_SUCCESS:
        return {
          ...state,
          loading: false,
          code: action.payload.code,
          success: action.payload.success,
          data: action.payload.data,
          error: "",
        };
      case CREATE_NEW_CAMPAIGN_FAILED:
        return {
          ...state,
          loading: false,
          code: action.payload.code || action.payload.status,
          success: action.payload.success || false,
          data: {},
          error: action.payload.message || action.payload.data || action.payload.error,
        };
  
      case CREATE_NEW_CAMPAIGN_RESET:
        return {
          ...initialState,
        };
  
      default:
        return state;
    }
  };
  
  export default createNewCampaign;
  
import React from "react";
import Loader from "../Loader";
import { ScreenLoader } from "./style";

const Index = ({ loadingText = "" }) => {
  return (
    <ScreenLoader gap={loadingText ? "20px" : "0px"}>
      <Loader />
      {loadingText ? <div className="loader-text">{loadingText}</div> : null}
    </ScreenLoader>
  );
};

export default Index;

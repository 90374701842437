import {
    GET_ALL_CAMPAIGNS_FILTERED_INIT,
    GET_ALL_CAMPAIGNS_FILTERED_SUCCESS,
    GET_ALL_CAMPAIGNS_FILTERED_FAILED,
    GET_ALL_CAMPAIGNS_FILTERED_RESET,
  } from "../../../constants";
  
  const initialState = {
    loading: false,
    data: {},
    code: "",
    success: false,
    error: "",
  };
  
  const getAllCampaignsFiltered = (state = initialState, action = {}) => {
    switch (action.type) {
      case GET_ALL_CAMPAIGNS_FILTERED_INIT:
        return {
          ...state,
          loading: true,
        };
      case GET_ALL_CAMPAIGNS_FILTERED_SUCCESS:
        return {
          ...state,
          loading: false,
          code: action.payload.code,
          success: action.payload.success,
          data: action.payload.data,
          error: "",
        };
      case GET_ALL_CAMPAIGNS_FILTERED_FAILED:
        return {
          ...state,
          loading: false,
          code: action.payload.code || action.payload.status,
          success: action.payload.success || false,
          data: {},
          error:
            action.payload.message || action.payload.data || action.payload.error,
        };
  
      case GET_ALL_CAMPAIGNS_FILTERED_RESET:
        return {
          ...initialState,
        };
  
      default:
        return state;
    }
  };
  
  export default getAllCampaignsFiltered;
  
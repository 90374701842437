import { MODAL_TOGGLE, MODAL_RESET } from "../../constants";

const initialState = {
  status: false,
  data: {},
};

const modal = (state = initialState, action = {}) => {
  switch (action.type) {
    case MODAL_TOGGLE:
      return {
        ...state,
        status: action.status,
      };
    case MODAL_RESET:
      return {
        ...state,
        status: false,
        data: {},
      };
    default:
      return state;
  }
};

export default modal;

import {
  DEACTIVATE_TOURNAMENTS_INIT,
  DEACTIVATE_TOURNAMENTS_SUCCESS,
  DEACTIVATE_TOURNAMENTS_FAILED,
  DEACTIVATE_TOURNAMENTS_RESET,
} from "../../../constants";

const initialState = {
  loading: false,
  data: {},
  code: "",
  success: false,
  error: "",
};

const deactivateTournament = (state = initialState, action = {}) => {
  switch (action.type) {
    case DEACTIVATE_TOURNAMENTS_INIT:
      return {
        ...state,
        loading: true,
      };
    case DEACTIVATE_TOURNAMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        code: action.payload.code,
        success: action.payload.success,
        data: action.payload.data,
        error: "",
      };
    case DEACTIVATE_TOURNAMENTS_FAILED:
      return {
        ...state,
        loading: false,
        code: action.payload.code || action.payload.status,
        success: action.payload.success || false,
        data: {},
        error: action.payload.message || action.payload.data || action.payload.error,
      };

    case DEACTIVATE_TOURNAMENTS_RESET:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default deactivateTournament;

import {
  ACTIVATE_GAMEDEVELOPER_INIT,
  ACTIVATE_GAMEDEVELOPER_SUCCESS,
  ACTIVATE_GAMEDEVELOPER_FAILED,
  ACTIVATE_GAMEDEVELOPER_RESET,
} from "../../../constants";

const initialState = {
  loading: false,
  data: {},
  code: "",
  success: false,
  error: "",
};

const activateGameDeveloper = (state = initialState, action = {}) => {
  switch (action.type) {
    case ACTIVATE_GAMEDEVELOPER_INIT:
      return {
        ...state,
        loading: true,
      };
    case ACTIVATE_GAMEDEVELOPER_SUCCESS:
      return {
        ...state,
        loading: false,
        code: action.payload.code,
        success: action.payload.success,
        data: action.payload.data,
        error: "",
      };
    case ACTIVATE_GAMEDEVELOPER_FAILED:
      return {
        ...state,
        loading: false,
        code: action.payload.code,
        success: action.payload.success,
        data: {},
        error: action.payload.message || action.payload.data,
      };

    case ACTIVATE_GAMEDEVELOPER_RESET:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default activateGameDeveloper;

import {
  GAME_VIDEO_INIT,
  GAME_VIDEO_SUCCESS,
  GAME_VIDEO_FAILED,
  GAME_VIDEO_RESET,
} from "../../../constants";

const initialState = {
  loading: false,
  data: [],
  code: "",
  success: false,
  error: "",
};

const gameVideo = (state = initialState, action = {}) => {
  switch (action.type) {
    case GAME_VIDEO_INIT:
      return {
        ...state,
        loading: true,
      };
    case GAME_VIDEO_SUCCESS:
      return {
        ...state,
        loading: false,
        code: action.payload.code,
        success: action.payload.success,
        data: action.payload.data,
        error: "",
      };
    case GAME_VIDEO_FAILED:
      return {
        ...state,
        loading: false,
        code: action.payload.status,
        success: false,
        data: {},
        error: action.payload.message || action.payload.error,
      };
    case GAME_VIDEO_RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default gameVideo;

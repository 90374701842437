import React, { StrictMode } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import loadable from "@loadable/component";
import axios from "axios";

import pkg from "../package.json";
import ScreenLoader from "./Common/ScreenLoader";
import configureStore from "./store/configureStore";

import "./index.css";
const Layout = loadable(() => import("./Layout"));

axios.interceptors.request.use(
  async function (config) {
    config.headers.Version = pkg.version;
    config.headers.Source = 3;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  async function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

const store = configureStore(window.__PRELOADED_STATE__);

ReactDOM.render(
  <Provider store={store}>
    <StrictMode>
      <BrowserRouter>
        <Layout fallback={<ScreenLoader />} />
      </BrowserRouter>
    </StrictMode>
  </Provider>,
  document.getElementById("root")
);

import {
    ACTIVATE_CAMPAIGNS_INIT,
    ACTIVATE_CAMPAIGNS_SUCCESS,
    ACTIVATE_CAMPAIGNS_FAILED,
    ACTIVATE_CAMPAIGNS_RESET,
  } from "../../../constants";
  
  const initialState = {
    loading: false,
    data: {},
    code: "",
    success: false,
    error: "",
  };
  
  const activateCampaigns = (state = initialState, action = {}) => {
    switch (action.type) {
      case ACTIVATE_CAMPAIGNS_INIT:
        return {
          ...state,
          loading: true,
        };
      case ACTIVATE_CAMPAIGNS_SUCCESS:
        return {
          ...state,
          loading: false,
          code: action.payload.code,
          success: action.payload.success,
          data: action.payload.data,
          error: "",
        };
      case ACTIVATE_CAMPAIGNS_FAILED:
        return {
          ...state,
          loading: false,
          code: action.payload.code,
          success: action.payload.success,
          data: {},
          error: action.payload.message || action.payload.data,
        };
  
      case ACTIVATE_CAMPAIGNS_RESET:
        return {
          ...initialState,
        };
  
      default:
        return state;
    }
  };
  
  export default activateCampaigns;
  
import {
    UPLOAD_MONETISATION_ICON_INIT,
    UPLOAD_MONETISATION_ICON_SUCCESS,
    UPLOAD_MONETISATION_ICON_RESET,
    UPLOAD_MONETISATION_ICON_FAILED,
  } from "../../../constants";
  
  const initialState = {
    loading: false,
    data: {},
    code: "",
    success: false,
    error: "",
  };
  
  const uploadMonetisationIcon = (state = initialState, action = {}) => {
    switch (action.type) {
      case UPLOAD_MONETISATION_ICON_INIT:
        return {
          ...state,
          loading: true,
        };
      case UPLOAD_MONETISATION_ICON_SUCCESS:
        return {
          ...state,
          loading: false,
          code: action.payload.code,
          success: action.payload.success,
          data: action.payload.data,
          error: "",
        };
      case UPLOAD_MONETISATION_ICON_FAILED:
        return {
          ...state,
          loading: false,
          code: action.payload.status,
          success: false,
          data: {},
          error: action.payload.message || action.payload.error,
        };
  
      case UPLOAD_MONETISATION_ICON_RESET:
        return {
          ...initialState,
        };
  
      default:
        return state;
    }
  };
  
  export default uploadMonetisationIcon;
  
import styled from "styled-components";

export const ScreenLoader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${(props) => (props.gap ? props.gap : "0px")};
  & > div > .loader {
    border: 5px solid rgb(31, 113, 255);
    border-top-color: transparent;
  }
  .loader-text {
    font-size: 22px;
    font-weight: 500;
    color: rgb(31, 113, 255);
  }
`;

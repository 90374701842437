import {
    UPDATE_AD_SLOTS_MAPPING_INIT,
    UPDATE_AD_SLOTS_MAPPING_SUCCESS,
    UPDATE_AD_SLOTS_MAPPING_RESET,
    UPDATE_AD_SLOTS_MAPPING_FAILED,
  } from "../../../constants";
  
  const initialState = {
    loading: false,
    data: {},
    code: "",
    success: false,
    error: "",
  };
  
  const updateAdSlotsMapping = (state = initialState, action = {}) => {
    switch (action.type) {
      case UPDATE_AD_SLOTS_MAPPING_INIT:
        return {
          ...state,
          loading: true,
        };
      case UPDATE_AD_SLOTS_MAPPING_SUCCESS:
        return {
          ...state,
          loading: false,
          code: action.payload.code,
          success: action.payload.success,
          data: action.payload.data,
          error: "",
        };
      case UPDATE_AD_SLOTS_MAPPING_FAILED:
        return {
          ...state,
          loading: false,
          code: action.payload.status,
          success: false,
          data: {},
          error: action.payload.message || action.payload.error,
        };
  
      case UPDATE_AD_SLOTS_MAPPING_RESET:
        return {
          ...initialState,
        };
  
      default:
        return state;
    }
  };
  
  export default updateAdSlotsMapping;
  